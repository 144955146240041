<template>
  <div v-if="permission.access">
    <div class="w-100 text-right">
      <b-button
        variant="primary"
        to="/vouchers/create"
        class="mr-1 mb-2"
        v-if="permission.create"
      >
        Create
      </b-button>
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        class="mb-2"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card no-body>
          <div class="m-2">
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- course -->
              <b-col cols="12" md="4">
                <div class="d-flex align-items-center justify-content-end">
                  <Select
                      v-model="search.course_id"
                      filterable
                      @on-change="SearchData"
                      :clearable="true"
                      name="course_id"
                      id="course_id"
                      placeholder="Select Purchase Coruse"
                  >
                  <Option
                      :value="course.id"
                      v-for="(course, index) in courses"
                      :key="index"
                      v-if="courses.length"
                  >{{ course.name }}</Option
                  >
                  </Select>
                </div>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="4">
                <div class="mb-1 d-flex align-items-center justify-content-end">
                  <b-form-input
                      v-model="search.search_data"
                      class="d-inline-block mr-1"
                      placeholder="Search..."
                  />
                </div>
              </b-col>

              <!-- Start From Date -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="start_from_date"
                      v-model="search.start_from_date"
                      id="start_from_date"
                      placeholder="Start Date From"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

              <!-- Start to Date -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="start_to_date"
                      v-model="search.start_to_date"
                      id="start_to_date"
                      placeholder="Start Date To"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

              <!-- End from Date -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="end_from_date"
                      v-model="search.end_from_date"
                      id="end_from_date"
                      placeholder="End Date From"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

              <!-- End to Date -->
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="end_to_date"
                      v-model="search.end_to_date"
                      id="end_to_date"
                      placeholder="End Date To"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th>Voucher</th>
                  <th class="text-center">Discount</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Validity Date</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                  <th class="align-middle" v-if="permission.delete">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(voucher, index) in vouchers.data"
                  :key="index"
                  v-if="vouchers.data.length"
                >
                  <td class="align-middle text-center">
                    {{ vouchers.from + index }}
                  </td>
                  <th>
                    <p class="mb-0">{{ voucher.name }}</p>
                  </th>
                  <td class="align-middle text-center">
                    <p class="mb-0">
                      {{ voucher.discount ? voucher.discount : 0
                      }}{{ voucher.discount_type == 1 ? "%" : "/-" }}
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <p class="mb-0">
                      <span
                        class="badge"
                        :class="
                          voucher.status == 1 ? 'badge-success' : 'badge-danger'
                        "
                      >
                        {{ voucher.status == 1 ? "Active" : "Inactive" }}
                      </span>
                      <span class="badge badge-primary" style="margin: 3px" title="Total Apply">
                        {{ voucher.max_apply_done || 0 }}
                      </span>
                      <span class="badge badge-info" title="Total Course Purchase">
                        {{ voucher.course_wise_max_apply_done || 0 }}
                      </span>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <div class="d-inline-block">
                      <span v-if="voucher.from_expiry_date">
                        {{ voucher.from_expiry_date | date_format }}</span
                      >
                      <span v-else>No Date Set</span>
                    </div>
                    -
                    <div class="d-inline-block">
                      <span v-if="voucher.to_expiry_date">
                        {{ voucher.to_expiry_date | date_format }}
                      </span>
                      <span v-else>No Date Set</span>
                    </div>
                  </td>
                  <td class="align-middle text-center">
                    <div class="btn-group">
                      <router-link
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        :to="'/vouchers/edit/' + voucher.id"
                      >
                        <feather-icon icon="EditIcon" />
                      </router-link>
                    </div>
                  </td>
                  <td v-if="permission.delete" class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="voucher.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ vouchers.from ? vouchers.from : 0 }} to
                  {{ vouchers.to ? vouchers.to : 0 }} of
                  {{ vouchers.total ? vouchers.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="vouchers"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        category_id: "",
        search_data: "",
        course_id: null,
        start_from_date: "",
        start_to_date: "",
        end_from_date: "",
        end_to_date: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      vouchers: {},

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,

      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getResults();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const vouchers = await this.callApi(
        "post",
        "/app/purchase/vouchers?page=" + this.search.page,
        this.search
      );
      if (vouchers.status == 200) {
        this.vouchers = vouchers.data.vouchers;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.DeleteAlert("/app/course/categories/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.vouchers.data.forEach((voucher) => {
          this.select.data.push(voucher.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "voucher.access") {
          this.permission.access = true;
        }

        if (permission.slug == "voucher.create") {
          this.permission.create = true;
        }

        if (permission.slug == "voucher.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "voucher.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.category_id": function (val) {
      this.SearchData();
    },
    "search.start_from_date": function (val) {
      this.SearchData();
    },
    "search.start_to_date": function (val) {
      this.SearchData();
    },
    "search.end_from_date": function (val) {
      this.SearchData();
    },
    "search.end_to_date": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.vouchers.data.length;
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    categories() {
      return this.$store.getters.getSubCategory;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
    courses() {
      return this.$store.getters.getActiveCourses;
    },
  },
};
</script>

<style></style>
